/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "다섯 번째 에피소드에서는 AWS 서울 리전의 DNS 장애, 클라우드, 원데이 이모콘 노션보러오션에 대해서 이야기를 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://it.chosun.com/site/data/html_dir/2018/11/22/2018112200758.html"
  }, "'쿠팡 접속불가' 아마존웹서비스(AWS) 장애 때문"), " -조선일보"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bleepingcomputer.com/news/technology/google-error-causes-widespread-internet-outage-in-japan/"
  }, "Google Error Causes Widespread Internet Outage in Japan")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Single_point_of_failure"
  }, "Single point of failure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/nacyo_t/status/1065517863153201152"
  }, "\"그리고 지구를 단일장애점으로 규정하고 인류를 대재앙으로부터 다중화하려는 엘런 머스크가 있다.\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datalab.naver.com/keyword/realtimeList.naver?datetime=2018-11-22T10%3A30%3A00&where=main"
  }, "당근마켓 실검 6위")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/compute/sla/"
  }, "AWS EC2 SLA와 장애 보상 정책")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr1.koreanair.com/korea/ko/about/news/press_release/2018_11_07/"
  }, "대한항공 AWS 이전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.open.go.kr/pa/infoWonmun/cateSearch/wonmunOrginlDetail.do?prdnDt=20180402160200&prdnNstRgstNo=DCT5ECCE039161C4CA704FFFFFBC6A7E7A0&returnUrl=/search/womn/govm_org.do"
  }, "성남시립도서관 홈페이지 통합구축 계획")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://sbscnbc.sbs.co.kr/read.jsp?pmArticleId=10000725092"
  }, "[단독] 대한항공, 대기업으론 첫 클라우드 서비스 쓴다"), " - SBS"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.etnews.com/20180807000235"
  }, "이스타항공 클라우드 탑승 완료…탈 데이터센터 신호탄되나"), " - 전자신문"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wordpress.org/plugins/jetpack/"
  }, "워드프레스 제트팩")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/events/1933862736915765/"
  }, "원데이 이모콘 X 노션보러오션")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85-%EB%8D%94-%EC%A2%8B%EC%9D%80-%EB%AC%B8%EC%84%9C%EB%8F%84%EA%B5%AC%EA%B0%80-%ED%95%84%EC%9A%94%ED%95%B4%EC%9A%94-notion%EC%9D%84-%EB%A7%8C%EB%82%98%EB%8B%A4-ff4f7e0414f"
  }, "스타트업, 더 좋은 문서도구가 필요해요! Notion을 만나다"), " - 당근마켓"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/store/"
  }, "Bear's Shop")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/"
  }, "Notion.so")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/Join-Us-e7aeb157238a4603a2964b28c646f07f"
  }, "Notion.so - Join us")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/daangn/07ca1fda22584d60a48ef43a8cf9bab0"
  }, "당근마켓과 함께 할 개발자를 찾고 있어요!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnbc.com/2017/09/07/how-facebook-graffiti-artist-david-choe-earned-200-million.html"
  }, "How this graffiti artist made $200 million overnight")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.mk.co.kr/newsRead.php?sc=30000001&year=2018&no=681139"
  }, "신세계·이마트 온라인 통합법인 내년 출범…1조 투자 유치 마무리 - 매일 경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23142086"
  }, "쿠팡, 2조2500억원 투자유치…사상 최대 규모 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nytimes.com/2018/05/16/technology/moviepass-economy-startups.html"
  }, "The Entire Economy Is MoviePass Now. Enjoy It While You Can")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.asiae.co.kr/news/view.htm?idxno=2018111911582199902&mobile=Y"
  }, "삼성전자, 대졸공채에 '파이썬' 도입...\"오픈소스로 생태계 선점\" - 아시아 경제")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
